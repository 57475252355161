import React, { useState }  from 'react';
import styled from 'styled-components';
import { navigate, graphql, Link } from "gatsby";
import { GatsbyImage, getSrc } from "gatsby-plugin-image";
import Parser from 'html-react-parser';
import Helmet from 'react-helmet';
import logo from '../assets/ventureweb_logo_white.png';
import logoIcon from '../assets/logo.png';
import Loader from '../assets/loader.png';
import Slider from "react-slick";
import Nav from '../components/Nav';
import { isLoggedIn } from "../utils/auth";
import Layout from "../components/layout";

//const ShowcasePostTemplate = ({  data: {post, prev, next, totalPosts}, pageContext}) => {

const ShowcasePostTemplate = ({  data: {post}, pageContext}) => {
  const [isActive, setActive] = useState("false");
 const handleToggle = () => {
    setActive(!isActive);
  };

    const isBrowser = typeof window !== `undefined` 
    if (!isLoggedIn()) {
        if (!isBrowser) return false
        // If we’re not logged in, redirect to the home page.
        navigate(`/sign-in`)
        return null
    }

    const regex = /(<([^>]+)>)/ig;
    const excerptLength = (str, length, ending) => {
        if (length == null) {
          length = 200;
        }
        if (ending == null) {
          ending = '...';
        }
        if (str.length > length) {
          return str.substring(0, length - ending.length) + ending;
        } else {
          return str;
        }
    };    
   
    const postTitle = Parser(post.title);
    const metaTitle = postTitle + "  | Ventureweb";
    const postUrl = "/our-work/" + post.slug;
    const postIndustry = post.spotlight.industriesTax;
    const postSolutions = post.spotlight.solutionsTax;
    const postServices = post.spotlight.servicesTax;
    const description = post.excerpt.replace(regex, '');
    //const { totalCount, thisPostNum } = pageContext;
    const { next, prev, totalCount, thisPostNum } = pageContext;
    const postImage = post.spotlight.backgroundImage;
    const metaImage = post.spotlight.backgroundImage;

    const showTestimonials = post.spotlight.showTestimonials;
    const testimonials = post.spotlight.testimonials;   
    var sliderType;

    console.log(getSrc(metaImage[0].localFile.childImageSharp.gatsbyImageData));
    //console.log(pageContext.totalPosts.data.allWpShowcaseItem.totalCount);
    console.log(thisPostNum);
    //Image Slider Variables
    const SliderImages = postImage.map((url, num) => {
        return (
            /*<img key={num} src={url.localFile.childImageSharp.fluid.src} alt="" />  */   
            <GatsbyImage key={num} image={url.localFile.childImageSharp.gatsbyImageData} alt=""/>          
        )
    });
     
    if(showTestimonials === true) {
        sliderType = 'testimonial-slider';
    }
    else if (SliderImages.length > 1) {
        sliderType = 'images-slider';
    }
    else {
        sliderType = '';
    }


    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    var settingsTestimonials = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true
    };

    //console.log(description);

    //Showcase categories
    const solutionItems = (
        postSolutions.map((solution, i) => {
          return (           
            <li key={i} dangerouslySetInnerHTML={{ __html: solution.name}}/>
          )
        })
    );

    const industryItems = (
        postIndustry.map((industry,j) => {
          return (           
            <em key={j} dangerouslySetInnerHTML={{ __html: (j ? ', ' : '') + industry.name}}/>
          )
        })
    );
    const serviceItems = (
        postServices.map((service,k) => {
          return  (
            <span key={k}>{(k ? ', ' : '') + service.name}</span>
          )
        })
    );

    
        const testimonialItems = (
            testimonials !== null ? 
            testimonials.map((testimonial,l) => {
                var quoteeName = testimonial.quoteeName;
                var quoteeLocation = ', '+testimonial.quoteeLocation;
                var quote = testimonial.quote.replace(regex, '');
                var quoteePicSrc = getSrc(testimonial.quoteeProfilePic.localFile.childImageSharp.gatsbyImageData); 
                console.log(quoteePicSrc);

                if(quoteePicSrc !== ''){
                    var classnm = ' has-pic';
                }
                return (
                    <div key={l} className={'testimonial'+classnm}>
                        <div className="testimonial-wrapper">
                            {quoteePicSrc && ( 
                                <div className="testimonial-img">
                                    <img src={quoteePicSrc} title={quoteeName+quoteeLocation} alt=""/>                            
                                </div>                                      
                            )}
                            <div className="testimonial-text">
                               <q>{Parser(quote)}</q> 
                                {quoteeName && ( 
                                    <div className="testimonial-footer">
                                        &mdash; {quoteeName} 
                                        {quoteeLocation && (
                                            <span>{quoteeLocation}</span>
                                        )}                        
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )
            })
            :
            ''        
        ); 




  return (           
            <Layout className="page-wrapper" onClick={handleToggle}>
            {/*<Layout className="page-wrapper"> ref={this.setWrapperRef}*/}
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={description} />
                {metaImage && ( <meta name="image" content={getSrc(metaImage[0].localFile.childImageSharp.gatsbyImageData)} />)}
                <meta itemprop="name" content={postTitle}/>
                <meta itemprop="description" content={description} />
                {metaImage && ( <meta itemprop="image"  content={getSrc(metaImage[0].localFile.childImageSharp.gatsbyImageData)}/>)}
                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:title" content={postTitle}/>
                <meta name="twitter:description" content={description} />
                <meta name="og:title" content={postTitle}/>
                <meta name="og:description" content={description} />
                {metaImage && ( <meta name="image" property="og:image"  content={getSrc(metaImage[0].localFile.childImageSharp.gatsbyImageData)}/>)}
                <meta name="og:url" content={postUrl} />
                <meta name="og:site_name" content="VentureWeb" />
                <meta name="og:type" content="website"/>
            </Helmet>
            <PostWrapper> 
                <Nav />
                <PostImages className={sliderType}>
                {showTestimonials === true && testimonials.length && SliderImages.length === 1 ? 
                    <div className="testimonials-container" style={{backgroundImage: `url(${getSrc(metaImage[0].localFile.childImageSharp.gatsbyImageData)})`}}> 
                        <Slider {...settingsTestimonials}>
                            {testimonialItems}
                        </Slider> 
                        </div>              
                :
                    SliderImages.length > 1 && testimonials === null ? 
                        <Slider {...settings}>
                            {SliderImages}
                        </Slider>                        
                :

                showTestimonials === true && testimonials.length && SliderImages.length > 1 ?
                <div className="testimonials-container" style={{backgroundImage: `url(${getSrc(metaImage[0].localFile.childImageSharp.gatsbyImageData)})`}} > 
                        <Slider {...settingsTestimonials}>
                            {testimonialItems}
                            {SliderImages.slice(1)}
                        </Slider> 
                        </div>
                :
                        /*<GatsbyImage sizes={postImage[0].localFile.childImageSharp.fluid} outerWrapperClassName="bgImage" alt=""/>*/
                        <GatsbyImage image={postImage[0].localFile.childImageSharp.gatsbyImageData} className="bgImage" alt=""/>
                }            
                    </PostImages>                 
                <PostContent id="contentPanel" className={!isActive ? 'panel-open' : 'panel-closed'}>      
                    <Panel className="panelWrapper">
                        <PanelTop className="panelTop" id="panelTop">
                            {/*<div className="logo" id="logo" ref={(section) => { this.Top = section; }}>*/}
                            <div className="logo" id="logo">
                                <Link to="/">
                                    <img src={logo} alt="" width="150"/>
                                </Link>
                            </div>
                            <Hgroup>
                                <h2 dangerouslySetInnerHTML={{ __html: post.title}}/>
                                <h6>[{serviceItems}]</h6>
                                <h6 className="industryItems">Industry: <span>{industryItems}</span></h6>
                            </Hgroup>
                            <IntroExcerpt className="introText" dangerouslySetInnerHTML={{ __html: excerptLength(post.excerpt.replace(regex, ''))}}/>
                            <PanelContent className="panelContent">
                            <PanelContentWrapper className="panelText">
                                <h4>Their Objective. Our Challenge</h4>	
                                <p dangerouslySetInnerHTML={{ __html: post.spotlight.theirObjectiveOurChallenge.replace(regex, '')}}/>
                                <h4>Our Expertise. Collaborative Solutions</h4>	
                                <p dangerouslySetInnerHTML={{ __html: post.spotlight.ourExpertiseCollaborativeSolutions.replace(regex, '')}} />
                                <h4>The Difference</h4>	
                                <p dangerouslySetInnerHTML={{ __html: post.spotlight.theDifference.replace(regex, '')}}/> 
                                
                                <Categories className="catBox">
                                <h4>Our Solution</h4>
                                <ul className="catList">
                                    {solutionItems}
                                </ul>
                            </Categories> 
                            </PanelContentWrapper>
                        </PanelContent> 
                        </PanelTop>                                  
                        <PanelBottom className="panelBottom"> 
                            <PanelButton type="button" className={!isActive ? 'panelButton panel-open' : 'panelButton panel-closed'} onClick={handleToggle}>                            
                                <span>
                                    <em className="pan-top" />
                                    <em className="pan-bottom" />
                                </span>
                            </PanelButton> 
                            <NavBlock>
                                <Link className="back-link" to="/our-work"><span>&lt;&lt;</span> Our Work</Link>
                                <ContactButton className="contact-btn">
                                    <a href="https://www.ventureweb.com/get-in-touch/" target="_blank"  rel="noopener noreferrer">Get in Touch</a>
                                </ContactButton>
                            </NavBlock> 
                            <ShowcaseNav className="showcase-nav">                        
                                <ShowcasePager className="showcase-pager">
                                    <ShowcasePagerInfo className="pager-title">
                                        {next && (                                     
                                            <div>
                                                <p>Next Project</p>
                                                <h6>
                                                    <Link to={`/our-work/`+next.slug} title={Parser(next.title)}>{Parser(next.title)}</Link>
                                                </h6>
                                            </div>
                                        )}
                                    </ShowcasePagerInfo>
                                    <ShowcasePagination className="pager-arrows">
                                    <div>{prev && (
                                            <Link to={`/our-work/`+prev.slug}className="arrow-navi prev" title={Parser(prev.title)}>&lt;</Link>
                                        )}
                                        {next && (
                                            <Link to={`/our-work/`+next.slug} className="arrow-navi next" title={Parser(next.title)}>&gt;</Link>
                                        )}
                                        </div>
                                        {totalCount && ( 
                                            <div className="project-number">{thisPostNum+1}/{totalCount}</div>
                                        )}
                                    </ShowcasePagination>                                     
                                </ShowcasePager>
                            </ShowcaseNav>                  
                        </PanelBottom>               
                    </Panel>
                </PostContent>
            </PostWrapper> 
      </Layout>
      );
    }
  export default ShowcasePostTemplate;

export const pageQuery = graphql`    
query showCaseTemplateById(
        # these variables are passed in via createPage.pageContext in gatsby-node.js
        $id: String!
        $previousPostId: String
        $nextPostId: String
    ) {
        # selecting the current post by id
        post: wpShowcaseItem(id: { eq: $id }) {
        title
        content
        slug
        excerpt
        modified
        date(formatString: "MMMM DD, YYYY")
            spotlight {
            isFeatured
            theirObjectiveOurChallenge
            ourExpertiseCollaborativeSolutions
            theDifference
            servicesTax {
                name
                slug
                termTaxonomyId
            }
            solutionsTax {
                name
                slug
                termTaxonomyId
            }
            industriesTax {
                name
                slug
                termTaxonomyId
            }
            backgroundImage {
                localFile {
                    childImageSharp {                    
                        gatsbyImageData(layout: CONSTRAINED,placeholder: TRACED_SVG)                    
                    }
                }
            }
            showTestimonials
            testimonials {
                quoteeProfilePic {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(layout: CONSTRAINED,placeholder: BLURRED)                    
                        }
                    }
                }
                quoteeName
                quoteeLocation
                quote
            }
            }
        }
        # this gets us the previous post by id (if it exists)
        previous: wpShowcaseItem(id: { eq: $previousPostId }) {
        uri
        title
        slug
        id
        }
        # this gets us the next post by id (if it exists)
        next: wpShowcaseItem(id: { eq: $nextPostId }) {
        uri
        title
        slug
        id
        }
        
    }
`;

const PostWrapper = styled.div`
    width: 100%;
    z-index: 0;
    @media (max-width: 1024px){
        background: #333f48;
    }    
`
const PostContent = styled.div`   
    position: fixed;    
    top: 0;
    left: 0;    
    bottom: 0;
    width: 400px;
    padding-bottom: calc(195px - 2.7rem); 
    color: #ffffff;
    z-index: 1;
    transition: all 0.8s ease-in-out;
    background: #425563;
    &::after {
        content: close-quote;
        text-indent: -9999px;
        background: #333f48 url(${logoIcon}) no-repeat -20px -20px;
        width: 0;
        height: 100%;
        line-height: 0;
        font-size: 1px;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
        transition: all 0.8s ease-in-out;
    }   
    &.panel-open {
        width: 90%;
        padding-bottom: 0;
        position: absolute;
        &::after {
            position: fixed;
            width: 90%;
        }
       .panelWrapper { 
        box-shadow: none;
            &::before {
                opacity: 1;
            }
            &::after {
                background-position: 0 100%;
                bottom: 0;
            }
            .introText {
                transform: translateX(-300%); 
                opacity: 0; 
                height: 0;
            }
            .panelTop {  
                height: auto;                
                hgroup {
                   margin-right: calc(400px - 2.3rem); 
                   padding-right: 0;
                }                
            }                       
           .panelContent {
                transform: scale(1,1) translate(0,0);
                opacity: 1;  
                flex: 0 0 100%;
                max-width: 100%;
            } 
            .panelText {
                padding : 0 1rem 0 1rem;
                height: auto;
                transform: none;  
                margin: 0;              
            }
            .panelBottom {   
                background: rgba(51,63,72,0.9);                          
                bottom: auto;
                top: 0;
                transform: translate(0,0);              
            }         
            .catBox {
                .catList {
                    margin: 0 0 0.6rem 0;
                }               
            }                     
        }
    }
      @media (max-width: 1024px)
      and (orientation: portrait) {
        width: 100%;    
        bottom: 50%; 
        padding-bottom: 0;
        &.panel-open {
            width: 100%; 
            bottom: 0;
            padding-bottom: 0;
            &:after {
                width: 100%;
            }
            .panelWrapper {
                padding-bottom: 150px;
                .panelTop {
                    max-height: 200%;
                }
                .panelBottom { 
                    width: 100%;
                    top: auto;       
                    position: relative;                                            
                }
                .contact-btn {
                    bottom: -2rem;
                }
            }        
        }
    }    
    @media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 823px) 
    and (-webkit-min-device-pixel-ratio: 3) { 
        position:relative;
        padding-bottom: 0; 
        width: 100%;  
    }      
    @media (max-width: 767px) {       
        position:relative;
        padding-bottom: 0;   
        width: 100%;
    }
` 

const Panel = styled.div`
    position: absolute; 
    z-index: 0;
    letter-spacing: 0.12rem;  
    transition: all 0.8s ease-in-out;   
    box-shadow: 0 0 5px 0 rgba(255,255,255,0.1);
    height: 100%;
    width: 100%;
    @media (max-width: 1024px)
    and (orientation: portrait) {
       position: relative;
       padding-bottom: 150px; 
    }
    @media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 823px) 
    and (-webkit-min-device-pixel-ratio: 3) { 
        position:relative; 
        padding-bottom: 0; 
    }
    @media (max-width: 767px) {       
        position:relative;
        padding-bottom: 0; 
    }    
`
const PanelTop = styled.div`  
    z-index: 2;    
    width: 100%;
    padding: 0 2.3rem 1rem 1.5rem;   
    transition: all 1.2s ease-in-out; 
    @media (max-width: 1024px)
    and (orientation: portrait) {
        max-height: 100%;
        transform: translate(0,0); 
        padding: 0 1.5rem 1rem 1.5rem;
    }
    @media (max-width: 767px) {
        max-height: none;
        padding: 0 1.5rem 1rem 1.5rem;
    }
`            
const Hgroup = styled.hgroup`
    padding: 0 0 0.8rem 0.8rem;
    position: relative;    
    margin-right: -380px; 
    padding-right: calc(400px - 2.3rem);  
    transition: all 0.8s ease-in-out; 
    &::after {
        display: block;
        position: absolute;
        bottom: 4px;
        left: 0.8rem;
        width: 50px;
        height: 4px;
        background: #ff8674;
        content: open-quote;
        text-indent: -9999px;
    }
    h2 {
        font-size: 30px;
        letter-spacing: 0.15rem;
        margin-bottom: 15px;
    }
    h6 {
        text-transform: uppercase;
        letter-spacing: 0.15rem;
        margin: 0 0 0.8rem 0;
    }
    h6.industryItems {        
        font-size: 85%;   
        span {
            text-transform: none;
            font-weight: 400;
            letter-spacing: 0.05rem;
            em {
                font-style: normal;
            }
        }     
    }
    @media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 823px) 
    and (-webkit-min-device-pixel-ratio: 3) { 
        padding: 1rem 0 0.8rem 0rem;
        margin-right: 0;
        &::after { 
            left: 0;
        }
        h2 {
            font-size: 25px;
            letter-spacing: 0.04rem;
        }  
    }    
    @media (max-width: 767px) {  
        padding: 1rem 0 0.8rem 0rem;
        margin-right: 0;
        &::after { 
            left: 0;
        }
        h2 {
            font-size: 25px;
            letter-spacing: 0.04rem;
        }
    }
`          
const IntroExcerpt = styled.p`
    padding: 0 0 0 0.8rem;  
    transition: all 0.7s ease-in-out;
    margin: 0.8rem 0 0 0;
    @media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 823px) 
    and (-webkit-min-device-pixel-ratio: 3) { 
        padding: 0; 
    }    
    @media (max-width: 767px) {  
        padding: 0;
    }
`

const PanelContent = styled.div`
    z-index: 2;                
    overflow: hidden;
    transition: all 1s ease-in-out;             
    transform: scale(0,0) translate(-300%,0);            
    opacity: 0;
    @media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 823px) 
    and (-webkit-min-device-pixel-ratio: 3) { 
        margin: 0;
        transform: scale(1,1) translate(0,0);
        opacity: 1;  
        max-width: 100%;
    }    
    @media (max-width: 767px) {  
        margin: 0;
        transform: scale(1,1) translate(0,0);
        opacity: 1;  
        max-width: 100%;         
    }
`              

const PanelContentWrapper = styled.div`    
    padding: 0;
    transition: all 1s ease-in-out;  
    h4 {
        margin: 1.5rem 0 1rem 0;
    }
`

const PanelBottom = styled.div`
    background: #333f48;
    z-index: 2;
    position: absolute;
    right: 0;
    top: calc(100% - 200px);
    width: 400px;
    height: 200px;
    transform: translate(0,0);    
    padding: 1.2rem 2.5rem 1.5rem 2.3rem;
    transition: all 0.8s ease-in-out;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;  
    align-items: flex-start;           
    @media (max-width: 1024px)
    and (orientation: portrait) {         
        width: 100%;
        height: 150px;
        transform: translate(0,0);         
        bottom: 0;
        top: auto;
        position: absolute;
    }
    @media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 823px) 
    and (-webkit-min-device-pixel-ratio: 3) { 
        width: 100%;  
        position: relative;       
        height: auto;
        top: auto;
        padding: 1.5rem 1.5rem 1.5rem 1.5rem;
        transform: none;
    }    
    @media (max-width: 767px) { 
        width: 100%;  
        position: relative;       
        height: auto;
        top: auto;
        padding: 1.5rem 1.5rem 1.5rem 1.5rem;
        transform: none;
    }
`            
const NavBlock = styled.div`
.back-link {  
    display: block;
    border: 2px solid #ff8674;
    color: #ffffff;
    padding: 15px 25px;  
    width: auto;
    margin: 0 0 1rem 0;              
    font-weight: 600;
    white-space: pre;  
    span {
        color: #ff8674;
    }
    &:hover {
        background: #ff8674;
        span {
            color: #ffffff;
        }
    }
}  
@media (max-width: 1024px)
and (orientation: portrait) {
    flex: 0 0 40%;
    max-width: 40%;
}
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 823px) 
and (-webkit-min-device-pixel-ratio: 3) {
    flex: 0 0 40%;
    max-width: 40%;
}
@media (max-width: 767px) {   
    flex: 0 0 100%;
    max-width: 100%;     
    .back-link { 
        margin: 0; 
        text-align: center;
        display: block;
    }
}
`
const Categories = styled.div`
    flex: none;   
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    width: 100%;
    h4 {
        margin-top: 0;
        flex: 1 0 auto;
        max-width: 100%;
    }          
    .catList { 
        list-style: none;
        margin: 0;
        padding: 0;
        flex: 1 0 auto;
        max-width: 100%;                    
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-start;                    
        flex-wrap: wrap;                   
        li {
            padding: 0.4rem 0.5rem;
            background: #ff8674;
            color: #ffffff;
            text-align: center;
            line-height: 1.4;
            margin: 0 0.5rem 0.5rem 0;
            font-weight: 600; 
            flex: 0 0 150px;
            max-width: 150px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 13px;
            letter-spacing: 0.08rem;
        }
    }
    @media (max-width: 1024px)
    and (orientation: portrait) {
        .catList {        
            margin: 0 0 1rem 0;
            li {
                flex: 0 0 31.3333%;
                max-width: 31.3333%;
            }
        }
    }
    @media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 823px) 
    and (-webkit-min-device-pixel-ratio: 3) { 
        transform: none; 
        position: relative;           
        top: 0; 
        opacity: 1;
        width: 100%;
        h4 {
            margin: 0 0 1rem 0;
        }        
        .catList {            
            margin: 0 0 1rem 0;
            li {
                flex: 0 0 32.2%;
                max-width: 32.2%;
            }   
        }
    }    
    @media (max-width: 767px) {          
        transform: none; 
        position: relative;           
        top: 0; 
        opacity: 1;
        width: 100%;
        h4 {
            margin: 0 0 1rem 0;
        }        
        .catList {            
            margin: 0 0 1rem 0;    
            li {
                flex: 0 0 31.3333%;
                max-width: 31.3333%;
            }        
        }
    }    
    @media (max-width: 650px)
    and (min-width: 480px) {
        .catList {  
            justify-content: center;      
            li {
                flex: 0 0 46%;
                max-width: 46%;
            }
        }  
    }
    @media (max-width: 480px) {
        .catList {
            li {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
`

const ShowcaseNav = styled.div`
    text-align: right;
    flex: none;
    max-width: 100%;   
    width: 100%;
    overflow: hidden;
    margin-left: auto; 
    margin-top: 0.8rem;
    @media (max-width: 1024px)
    and (orientation: portrait) {
        flex: 0 0 60%;
        max-width: 60%; 
        margin-top: 0;
    }

    @media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 823px) 
    and (-webkit-min-device-pixel-ratio: 3) {
        flex: 0 0 60%;
        max-width: 60%;
    }

    @media (max-width: 767px) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 0;
    }
`
                             
const ShowcasePager = styled.div`                    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;  
    align-items: stretch;   
    justify-content: flex-end;
    @media (max-width: 767px) {
        justify-content: flex-start;
    }
`

const ShowcasePagerInfo = styled.div`
    font-size: 11px;
    line-height: 16px;
    flex: 0 0 60%;
    max-width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    div {
        flex: 0 0 auto;
        width: 100%;
    }
    p {        
        margin: 0 0 4px 0;
    }
    h6 {        
        margin: 0;
        font-size: 13px;
        line-height: 1.1;
        a {
            color: #ffffff;
        }
    }
    @media (max-width: 767px) {
        text-align: left;
        font-size: 13px;        
        h6 {
            font-size: 16px;
        }
    }
    @media (max-width: 479px) {
        flex: 0 0 70%;
        max-width: 70%;
    }
`
const ShowcasePagination = styled.div` 
    font-size: 20px;
    line-height: 1;
    font-weight: 700;    
    flex: 0 0 auto;
    max-width: none;
    padding-left: 1.5rem;
    text-align: right;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-end;
    .arrow-navi {
        flex: 0 0 auto;
        display: inline-block;
        background: rgba(118,134,146, 0.5);
        width: 16px;
        height: 16px;
        line-height: 16px;
        margin: 0 0 4px 0;
        font-size: 12px;
        color: #ffffff; 
        border-radius: 100%;
        text-align: center;
        cursor: pointer;
        font-weight: 400;
        &:hover {
            background: rgba(118,134,146, 1); 
        }                           
    }
    .prev {
        margin-right: 5px;
        padding-left: 2px;
    }
    .next {
        padding-left: 2px;
    }
    .project-number {
    }
    @media only screen 
    and (min-device-width: 1024px) 
    and (max-device-width: 1366px) 
    and (-webkit-min-device-pixel-ratio: 1.5) {
        .arrow-navi {
            font-size: 20px;
            width: 30px;
            height: 30px;
            line-height: 30px;
            margin: 0 5px 15px 0;
        }
        .next {
            margin-right: 0;
        }  
    }
    @media (max-width: 1024px) {       
        .arrow-navi {
            font-size: 20px;
            width: 30px;
            height: 30px;
            line-height: 30px;
            margin: 0 5px 15px 0;
        }
        .next {
            margin-right: 0;
        }
    }    
    @media (max-width: 767px) {
        flex: 0 0 40%;
        max-width: 40%;
        .arrow-navi {
            font-size: 20px;
            width: 33px;
            height: 33px;
            line-height: 33px;
            margin: 0 5px 15px 0;
        }
        .next {
            margin-right: 0;
        }
    }

    @media (max-width: 479px) {
        flex: 0 0 30%;
        max-width: 30%;
        padding-left: 0.5rem;
    }

`

const ContactButton = styled.div`                       
padding: 0 1rem 0 0;  
z-index: 2;
text-transform: uppercase; 
a {
    position: relative;   
    display: inline-block;
    color: #ffffff;
    padding: 0 0 0 3.5rem; 
    font-size: 14px;                    
    &::before {
        height: 0.08rem;
        width: 2.5rem;
        background: #ffffff;
        display: block;
        position: absolute;
        top: 0.6rem;
        left: 0;
        content: open-quote;
        text-indent: -9999px;
    }
    &:hover {
        color: #64ccc9;
        &::before {
            background: #64ccc9;            
        }                        
    }
} 
@media (max-width: 767px) {  
    margin: 1rem auto 0 auto;
    padding: 0.5rem;
    position: relative;
    text-align: center;
    background: transparent;
    a {
        padding: 1rem 3.5rem;
        &::before {
            top: 1.6rem;
        }
        &::after {
            height: 0.08rem;
            width: 2.5rem;
            background: #ffffff;
            display: block;
            position: absolute;
            top: 1.6rem;
            right: 0;
            content: open-quote;
            text-indent: -9999px;
        }
    }
}
`

const PanelButton = styled.button`
    position: absolute;
    top 0;
    right: -25px;
    background: #ff8674;
    border: 0;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    z-index: 2;
    margin: -25px 0 0 0;
    transition: all .8s ease-in-out 0s;
    outline: 1px solid transparent;
    cursor: pointer;
    span {
        display: block;           
        position: relative;
        top: 0;
        height: 30px;
        width: 80%;
        margin: 0 auto;  
        transition: all 1s ease-in-out 0s;       
    }
    .pan-top,
    .pan-bottom {
        height: 5px;
        display: block;
        position: absolute;
        top: 13px;
        width: 100%;
        background-color: #ffffff;
    }
    .pan-top {
        transform: rotate(90deg);
    }
    .pan-bottom {        
    }
    &.panel-open {
        top 100%;
        outline: 1px solid transparent;
        span {
            transform: rotate(225deg);
        }        
    }
    @media (max-width: 1024px)
    and (orientation: portrait) {
        top: 100%;
        right: 50%;   
        margin: -25px -25px 0 0;
        &.panel-open {
           top: calc(100% - 50px);
           margin: 0 -25px 0 0;
        }
    }
    @media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 823px) 
    and (-webkit-min-device-pixel-ratio: 3) { 
        display: none;
    }   
    @media (max-width: 767px) {  
        display: none;
    }
`

const PostImages = styled.div`
    position: fixed;
    z-index: 0;
    background: transparent url(${Loader}) no-repeat 50% 50%;   
    left: 353px;
    top: 0;
    bottom: 0;
    width: calc(100% - 353px);   
    line-height: 1;
    .testimonials-container {
        height: 100%;
        text-align: center;
        padding-left: 47px;  
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #768692;
        position: relative;
        &:before {
            content: open-quote;
            text-indent: -9999px;
            overflow: hidden;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(255,255,255,0.6);
        }
        .slick-slider {
        }
        .slick-dots {           
        }
        .testimonial-wrapper {
            margin: 0 auto;
            padding-top: 6rem;
            padding-bottom: 2rem;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            text-align: center;           
            width: 100%;
            max-width: 80%;
            padding-left: 2rem;
            padding-right: 2rem; 
        }
        .testimonial-img {           
            flex: 0 0 100%;
            max-width: 100%;
            display: block;
            margin-bottom: 3rem;
            border-radius: 100%; 
            margin: 0 auto;
            text-align: center; 
            position: relative;  
            img {              
               border-radius: 100%;                
               width: 100%;
               max-width: 220px;                    
               z-index: 1;
               margin: 0 auto;  
               position: relative;
               border: 7px solid #ffffff;                
               box-shadow:  0 10px 40px rgba(0,0,0,0.5);                    
            }
        } 
        .testimonial-text {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
            background: rgba(255,255,255,0.8);
            box-shadow:  0 10px 40px rgba(0,0,0,0.5);
            margin: 3rem auto 0 auto;
            padding: 2rem;
            font-size: 1.5rem;
            line-height: 1.8;              
            text-align: left;  
            font-style: italic;
            q {
                quotes: "“" "”" "‘" "’";  
                padding: 0 0 0 2rem;
                display: block;                                       
                &:before {
                    content: open-quote;
                    font-size: 3rem; 
                    line-height: 1;
                    font-family: serif;
                    font-weight: 700;
                    position: relative;
                    left: -2rem;
                    margin-right: -1.5rem;
                }
                &:after {
                    content: close-quote;
                    font-size: 3rem;
                    line-height: 1;
                    font-family: serif;
                    font-weight: 700;                   
                } 
            }
            .testimonial-footer {
                margin-top: 1rem;
                text-align: right; 
                font-size: 1.1rem;
                line-height: 1;
                font-weight: 600;
                font-style: normal;
            }
        }
    }
    .slick-list, 
    .slick-slider, 
    .slick-track {
        position: relative;
        display: block;
    }
    .slick-loading .slick-slide, 
    .slick-loading .slick-track {
        visibility: hidden;
    }
    .slick-slider {
        box-sizing: border-box;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
    }
    .slick-list {
        overflow: hidden;
        margin: 0;
        padding: 0;
    }
    .slick-list:focus {
        outline: 0;
    }
    .slick-list.dragging {
        cursor: pointer;
        cursor: hand;
    }
    .slick-slider .slick-list, 
    .slick-slider .slick-track {
        transform: translate3d(0, 0, 0);
    }
    .slick-track {
        top: 0;
        left: 0;
    }
    .slick-track:after, 
    .slick-track:before {
        display: table;
        content: '';
    }
    .slick-track:after {
        clear: both;
    }
    .slick-slide {
        display: none;
        float: left;
        height: 100%;
        min-height: 1px;
    }
    [dir=rtl] .slick-slide {
        float: right;
    }
    .slick-slide img {
        display: block;
    }
    .slick-slide.slick-loading img {
        display: none;
    }
    .slick-slide.dragging img {
        pointer-events: none;
    }
    .slick-initialized .slick-slide {
        display: block;
    }
    .slick-vertical .slick-slide {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
    .slick-arrow.slick-hidden {
        display: none;
    }     
    .slick-arrow {
        color: #ff8674;
        cursor: pointer;
        font-size: 1px;
        position: absolute;
        top: 50%;
        margin-top: -25px;
        z-index: 1;
        border: 0;
        padding: 0;
        overflow: hidden;  
        height: 50px;
        width: 25px;
        outline: 1px solid transparent;
        &:before {
            content: close-quote;
            width: 0px;
            height: 0px;            
	        border-top: 15px solid transparent;
	        border-bottom: 15px solid transparent;
        }      
    }  
    .slick-next {
        right: 2.5rem;
        &:before {
            border-left: 25px solid #ff8674;
        }
    }
    .slick-prev {
        left: calc(1.5rem + 50px);
        &:before {
            border-right: 25px solid #ff8674;
        }
    }
    .slick-dots {        
        position: fixed;
        width: calc(100% - 400px);        
        text-align: center;
        bottom: 20px;
        left: 400px;
        margin: 0;
        padding: 0;
        text-align: center;
        li {
            display: inline-block;
            margin: 0 4px;
            button {
                border: 0;                
                color: #ff8674;
                text-indent: -9999px;
                background: #ff8674;
                border-radius: 100%;
                width: 14px;
                height: 14px;
                padding: 0;
                margin: 0;
                line-height: 14px;
                outline: 1px solid transparent;
                overflow: hidden;
                &:focus,
                &:active {
                    outline: 1px solid transparent;
                }
            }
            &.slick-active {
                button {
                    background: #333f48;                                            
                }
            }            
        }
    }        
    @media (max-width: 1440px) {
        .testimonials-container {
            .slick-slider {
                padding-bottom: 30px;
             }
            .slick-prev {
                left: calc(1.5rem + 20px);
            }
            .testimonial-wrapper {
                padding-top: 2.5rem;
            }
            .testimonial-img {               
                padding: 0;
            }
            .testimonial-text {                
                margin-top: 1.5rem; 
                font-size: 1.1rem;
                padding: 0.9rem;               
                q {
                    padding: 0 0 0 0.9rem;
                    &:before {                        
                        font-size: 1.5rem; 
                        line-height: 1;
                        font-family: serif;
                        font-weight: 700;
                        position: relative;
                        left: -1.2rem;
                        margin-right: -0.8rem;
                    }
                    &:after {                       
                        font-size: 1.5rem;               
                    } 
                } 
            }
        }
    }
    @media (max-width: 1024px) { 
       .testimonials-container {
            .testimonial-wrapper {
                padding-left: 2rem;
                align-items: flex-start;
            }        
            .testimonial-img {
                margin-top: 1.2rem;
                flex: 0 0 40%;
                max-width: 40%; 
            }            
            .testimonial-text {                
                margin-top: 2rem;
                margin-bottom: 2rem;
                padding: 0.9rem;
                font-size: 1.1rem;
                flex: 1 0 75%;
                max-width: 75%; 
                q {
                    padding: 0 0 0 0.9rem;
                    &:before {                        
                        font-size: 1.8rem; 
                        line-height: 1;
                        font-family: serif;
                        font-weight: 700;
                        position: relative;
                        left: -1.2rem;
                        margin-right: -0.8rem;
                    }
                    &:after {                       
                        font-size: 1.8rem;               
                    } 
                } 
                .testimonial-footer {
                    margin-top: 0.8rem;
                    font-size: 0.85rem;
                }  
            }
        }    
    }    
    @media (max-width: 1024px)
    and (orientation: portrait) {
        height: 50%;      
        width: 100%;
        left: 0;
        top: auto;
        bottom: 0;   
        &.testimonial-slider {
            height:50%;
        } 
        .slick-dots {           
            width: 100%;         
            bottom: 20px;
            left: 0;                   
        }
        .slick-next {
            right: 1.5rem;
        }
        .slick-prev {
            left: 1.5rem;
        }  
        .testimonials-container {
            padding-left: 0;
            .slick-slider {               
            }           
            .testimonial-wrapper {               
                padding-top: 3.5rem;
                padding-left: 0;                
            }            
            .testimonial-img {
                flex: 0 0 16%;
                max-width: 16%;                
                img {
                    border-width: 4px;
                }
            }            
            .testimonial-text { 
                margin-top: 1.2rem;
                padding: 0.8rem;
                font-size: 1.1rem;
                flex: 0 0 75%;
                max-width: 75%; 
                q {
                    padding: 0 0 0 0.9rem;
                    &:before {                        
                        font-size: 1.5rem; 
                        line-height: 1;
                        font-family: serif;
                        font-weight: 700;
                        position: relative;
                        left: -1.2rem;
                        margin-right: -0.8rem;
                    }
                    &:after {                       
                        font-size: 1.5rem;               
                    } 
                } 
                .testimonial-footer {
                    margin-top: 0.8rem;
                    font-size: 0.85rem;
                }  
            }          
        }      
    }
    @media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 823px) 
    and (-webkit-min-device-pixel-ratio: 3) { 
        position: relative;
        left: 0;
        width: 100%;       
        .slick-list {
            margin-bottom: -2px;
        }
        .slick-dots {
            top: auto;
            position: absolute;
            width: 100%;         
            bottom: 5px;
            left: 0;                   
        }
        .slick-next {
            right: 1.5rem;
        }
        .slick-prev {
            left: 1.5rem;
        }  
        .testimonials-container {
            padding-left: 0;
            .slick-slider {
                padding-bottom: 30px;
            }            
            .testimonial-wrapper {
                flex-direction: row;
                align-items: flex-start;  
                flex-wrap: wrap;
                padding-top: 1rem;
                margin-bottom: 3rem;
                padding: 1.5rem;
            }            
            .testimonial-img {
                margin-bottom: 0;                
                flex: 0 0 21%;
                max-width: 21%;                
                img {
                    border-width: 4px;
                }
            }            
            .testimonial-text {                
                margin-top: 0;
                padding: 0.5rem;
                font-size: 0.9rem;
                flex: 0 0 75%;
                max-width: 75%; 
                q {
                    padding: 0 0 0 0.9rem;
                    &:before {                        
                        font-size: 1.5rem; 
                        line-height: 1;
                        font-family: serif;
                        font-weight: 700;
                        position: relative;
                        left: -1.2rem;
                        margin-right: -0.8rem;
                    }
                    &:after {                       
                        font-size: 1.5rem;               
                    } 
                } 
                .testimonial-footer {
                    margin-top: 0.8rem;
                    font-size: 0.8rem;
                }  
            }          
        }       
    }
    @media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 823px) 
    and (orientation: landscape) 
    and (-webkit-min-device-pixel-ratio: 3) { 
        max-width: 70%;
        margin: 0 auto;        
        .slick-next {
            right: -2.5rem;
        }
        .slick-prev,
        .testimonials-container .slick-prev  {
            left: -2.5rem;
        } 
        .testimonials-container {
            padding-left: 0;
            .testimonial-wrapper {
                flex-direction: row;
                align-items: flex-start;  
                flex-wrap: wrap;
                margin-bottom: 3rem;
                padding: 1rem 0 0 0;
            }
        }        
    }
    @media (max-width: 767px) {  
       position: relative;
        left: 0;
        width: 100%;
        background: #425563;  
        .slick-arrow {
            top: 50%;
            bottom: auto;
        } 
       .slick-dots {
            top: auto;
            position: absolute;
            width: 100%;         
            bottom: 5px;
            left: 0;                   
        }        
        .slick-list {
            margin-bottom: -2px;
        }
        .testimonials-container {
            padding-left: 0;
            .slick-slider {
               padding-bottom: 0;
            }
            .testimonial-wrapper {
                flex-direction: row;
                align-items: flex-start;  
                flex-wrap: wrap;
                margin-bottom: 3rem;
                padding: 1rem 0 0 0;
            }            
            .testimonial-img {
                margin-bottom: 0;
                flex: 0 0 21%;
                max-width: 21%;                
                padding: 0.3rem;
                img {
                    border: 2px solid #ffffff; 
                }
            }            
            .testimonial-text {                
                margin-top: 0.5rem;
                padding: 0.5rem;
                font-size: 0.9rem;
                flex: 0 0 75%;
                max-width: 75%; 
                q {
                    padding: 0 0 0 0.9rem;
                    &:before {                        
                        font-size: 1.5rem; 
                        line-height: 1;
                        font-family: serif;
                        font-weight: 700;
                        position: relative;
                        left: -1.2rem;
                        margin-right: -0.8rem;
                    }
                    &:after {                       
                        font-size: 1.5rem;               
                    } 
                } 
                .testimonial-footer {
                    margin-top: 0.8rem;
                    font-size: 0.8rem;
                }  
            }          
        }       
    }    
    @media (max-width: 667px)
    and (min-width: 320px)
    and (orientation: landscape) {
        max-width: 70%;
        margin: 0 auto;
        .testimonials-container {
            padding-left: 0;
        }
        .slick-next {
            right: -2.5rem;
        }
        .slick-prev,
        .testimonials-container .slick-prev  {
            left: -2.5rem;
        }   
        .testimonials-container {
            .testimonial-wrapper {
                margin-bottom: 0;
            }        
        } 
    }
    @media (max-width: 479px) { 
        max-width: none;
        .slick-next {
            right: 0;
        }
        .slick-prev  {
            left: 0;
        }        
        .testimonials-container {
            .slick-prev  {
                left: 0;
            }
            .testimonial-wrapper {
                margin-bottom: 0;
            }            
            .testimonial-img {
                margin-bottom: 10px;
                flex: 0 0 50%;
                max-width: 50%;
                margin: 0 auto 1rem;
            }            
            .testimonial-text { 
                flex: 0 0 100%;
                max-width: 100%; 
                padding: 0.9rem;
                box-shadow:  0 10px 20px rgba(0,0,0,0.5);
                .testimonial-footer {
                    font-size: 0.7rem;
                }
            }
        }
    }
    @media (max-width: 479px)
    and (orientation: landscape) {    
        .slick-next {
            right: 0;
        }
        .slick-prev,
        .testimonials-container .slick-prev  {
            left: 0;
        }   
        .testimonials-container {
            .testimonial-wrapper {
                margin-bottom: 0;
            }        
        }
    }
`